<template>
  <div class="container-base section-container-padding bg-white">
    <div class="myproj-container settings-my-instances-tabs knowledge">
      <knowledge-tabs tab-name="questions"/>
      <b-row>
        <b-col class="margin-cards ask-question-result-margin">
          <div class="ask-question-result-title">
            <back-button :title="$t('knowledge.questions.buttons.back')"/>
            <span v-if="!editing" class="openSans-100 fz-28">{{ question.text }}</span>
            <b-form-input v-else class="li-header-title openSans-100 fz-28 input-margin" size="sm" ref="questionEdit"
                          v-model="questionEdit"
                          v-on:keyup.enter="submitEdit"
                          v-on:keyup.esc="cancelEdit">
            </b-form-input>
          </div>

          <b-row v-if="canModifyQA()" :class="is_mobile ? 'justify-space-between' : 'justify-content-end'" class="qa-detail-action-bar">
            <span  :class="is_mobile ? 'mb-4' : 'padding-left-span'" class="openSans-100 fz-14 cursor-pointer" @click="setFavorite">
              <img class="margin-action-icons" src="/assets/images/icon_favorite_true.svg" v-if="favorite" />
              <img class="margin-action-icons" src="/assets/images/icon_favorite_false.svg" v-else />
              {{ $t('myApps.qaPairs.actionIconsQuestion.popular') }}
            </span>
            <span :class="is_mobile ? 'mb-4' : 'padding-left-span'" class="openSans-100 fz-14 cursor-pointer" @click="removeQA">
              <img class="margin-action-icons" src="/assets/images/icon_close.svg"> {{ $t('myApps.qaPairs.actionIconsQuestion.remove') }}
            </span>
              <span>
              <span :class="is_mobile ? 'mb-4' : 'padding-left-span'" class="openSans-100 fz-14 cursor-pointer" @click="editQuestion" v-if="!editing">
                <img class="margin-action-icons" src="/assets/images/icon_edit.svg"> {{ $t('myApps.qaPairs.actionIconsQuestion.edit') }}
              </span>
              <span :class="is_mobile ? 'mb-4' : 'padding-left-span'" class="openSans-100 fz-14 cursor-pointer" @click="submitEdit" v-else>
                <img class="margin-action-icons" src="/assets/images/icon_checkmark.svg"> {{ $t('myApps.qaPairs.actionIconsQuestion.save') }}
              </span>
            </span>
            <span :class="is_mobile ? 'mb-4' : 'padding-left-span d-none'" class="openSans-100 fz-14 cursor-pointer" v-if="!editing">
              <img class="margin-action-icons" src="/assets/images/icon_navbar_search.svg"> {{ $t('myApps.qaPairs.actionIconsQuestion.searchResults') }}
            </span>
            <span :class="is_mobile ? 'mb-4' : 'padding-left-span'" class="openSans-100 fz-14 cursor-pointer" @click="cancelEdit" v-if="editing">
              <img class="margin-action-icons" src="/assets/images/icon_close.svg"> {{ $t('myApps.qaPairs.actionIconsQuestion.cancel') }}
            </span>
          </b-row>
          <response-card v-for="response in responses" :key="response.id" :data="response"
                         :input-id="inputId" :input-text="question.text" :callback="getQuestion"
                         :can-modify="canModifyQA()"/>

          <div v-if="canModifyQA()" class="qa-add-new-answer text-align-left">
            <span class="text-left-display">{{ $t('knowledge.questions.response.labels.addAdditionalAnswer') }}</span>
            <b-card class="mb-2">
              <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'edit' ? 'active-tab' : ''"  @click="editorMode = 'edit'">
                {{ $t('knowledge.questions.response.labels.write')}}
              </span>
              <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'preview' ? 'active-tab' : ''"  @click="editorMode = 'preview'">
                {{ $t('knowledge.questions.response.labels.preview')}}
              </span>
              <v-md-editor v-model="newAnswer.text" class="margin-editor" height="400px" :mode="editorMode"
                           left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code" right-toolbar="">
              </v-md-editor>
              <vue-tags-input
              class="ask-tags-input qa-new-tags-input"
              v-model="tagsInput.currentTag"
              :tags="tagsInput.tagsSearch"
              :autocomplete-items="filteredTags"
              :add-only-from-autocomplete="true"
              @tags-changed="newTags => tagsInput.tagsSearch = newTags" />

              <b-row class="ml-0">
                <img src="/assets/images/icon_smartsearch.svg">
                <span @click="displaySmartSearch"
                      class="cursor-pointer fz-14 openSans-400 smart-search-button-label">Search Knowledge Base</span>
              </b-row>

              <div class="d-flex justify-content-end">
                <button type="button" :disabled="waitingAddition" class="btn openSans-600 fz-12 ta-center button-secondary ml-auto" @click="saveNewAnswer">
                  {{ $t('knowledge.questions.buttons.addNewAnswer')}}
                </button>
              </div>

            </b-card>
          </div>

        </b-col>
      </b-row>
      <smart-search-modal @useResponses="useResponses"/>
    </div>
    <b-overlay :show="waitingAddition" no-wrap>
    </b-overlay>
  </div>
</template>

<script>
import QAService from "@/modules/knowledge/services/QAService.js"
import LibraryService from '@/modules/library/services/LibraryService.js'
import { PERM_MODIFY_QA } from '@/modules/security/services/SecurityService.js'

import KnowledgeTabs from "@/modules/knowledge/components/KnowledgeTabs.vue"
import ResponseCard from "@/modules/knowledge/components/ResponseCard.vue"
import BackButton from "@/modules/common/components/BackButton.vue"
import SmartSearchModal from "@/modules/knowledge/components/KnowledgeSmartSearchModal.vue"


export default {

  name: 'QuestionDetail',

  components: {
    KnowledgeTabs, BackButton, ResponseCard, SmartSearchModal
  },
  data () {
    return {
      inputId: this.$route.params.id,
      question: {},
      responses: [],
      questionEdit: '',
      editorMode: 'edit',
      favorite: false,
      editing: false,
      waitingAddition: false,
      newAnswer: {
        text: ''
      },
      tagsInput: {
        currentTag: '',
        tagsSearch: []
      },
      botTags: [],
    }
  },
  computed: {
    filteredTags() {
      return this.botTags.filter(tag => {
        return tag.text.toLowerCase().indexOf(this.tagsInput.currentTag.toLowerCase()) !== -1
      })
    },
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    },
    botPermissions() {
      return this.$store.getters.getInstancePermissions
    }
  },
  methods: {
    useResponses(responses) {
      Array.prototype.forEach.call(responses, response => {
       if (response.passage_path) {
        this.newAnswer.text += response.html
       } else {
        this.newAnswer.text += response.answer
       }
      response.tags.forEach(tag => {
        if(this.tagsInput.tagsSearch.includes(tag) === false) this.tagsInput.tagsSearch.push(tag)
      }) 
      });
    },
    displaySmartSearch(){
      this.$bvModal.show('smartSearchModal')
    },
    getBotTags() {
      let botName = this.$router.currentRoute.params.bot
      LibraryService.getBotTags(botName).then(response => {
        response.data.forEach(tag => {
          this.botTags.push({text: tag})
        })
      })
    },
    getQuestion() {
      QAService.getQuestion(this.$router.currentRoute.params.bot, this.inputId).then(response => {
        this.question = response.data
        this.favorite = this.question.favorite
        this.responses = this.question.responses
        this.questionEdit = this.question.text
      }, error => {
        console.log(error)
        this.$router.push(`/${this.$router.currentRoute.params.bot}/my-apps/knowledge/questions`)
      })
    },
    editQuestion() {
      this.editing = true
      this.$nextTick(() => this.$refs.questionEdit.focus())
    },
    cancelEdit() {
      this.questionEdit = this.question.text
      this.editing = false
    },
    submitEdit() {
      this.question.text = this.questionEdit
      this.editing = false
      this.waitingAddition = true
      QAService.modifyText(this.inputId, this.questionEdit).then(() => {
        this.waitingAddition = false
      })
    },
    setFavorite() {
      let value = !this.favorite
      QAService.setFavorite(this.inputId, value).then(response => {
        this.favorite = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    removeQA() {
      this.waitingAddition = true
      QAService.removeQA(this.inputId).then(() => {
        this.waitingAddition = false
        this.$router.push(`/${this.$router.currentRoute.params.bot}/my-apps/knowledge/questions`)
      })
      .catch(error => {
        console.log(error)
        this.waitingAddition = false
      })
    },
    saveNewAnswer() {
      this.waitingAddition = true
      let data = {
        input_text: this.question.text,
        response_text: this.newAnswer.text,
        tags: this.tagsInput.tagsSearch.map(tag => tag.text ? tag.text : tag)
      }
      QAService.save(data).then(() => {
        this.getQuestion()
        this.waitingAddition = false
        this.cleanNewQa()
      }).catch(error => {
        console.log(error)
      })
    },
    cleanNewQa() {
      this.newAnswer.text = ""
      this.tagsInput.tagsSearch = []
    },
    canModifyQA() {
      return this.botPermissions.indexOf(PERM_MODIFY_QA) > -1
    }
  },
  created() {
    this.getQuestion()
    this.getBotTags()
  }
}
</script>

<style lang="scss" scoped>

.ask-question-result-title {
  text-align: left;
  margin-left: -15px;
  max-width: 950px;
  margin-top: 15px;
  margin-bottom: 27px;
}

.qa-detail-action-bar {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 11px;
}

.padding-left-span {
  padding-left: 40px!important;
}

.qa-add-new-answer {
  text-align: left;
  margin-left: -15px;
  max-width: 1000px;
  margin-top: 15px;
  margin-bottom: 27px;
}

.text-left-display {
  text-align: left!important;
  display: block;
}

  ::v-deep {

  @media (min-width: 320px) and (max-width: 1024px) {
    .justify-space-between {
      justify-content: space-between;
    }

    .ask-question-result-margin {
      padding: 10px 20px;
    }

    .ask-question-result-title {
      margin-left: 0px;
      margin-right: 0px;
    }

    .back-button-span {

      a {
        margin-top: 0px;
      }
    }

    .myproj-card.knowledge-card {
      width: 100%;
    }

    .qa-add-new-answer, .knowledge-tags-container {
      margin-left: 0px;
    }

    .knowledge-card {
      margin-bottom: 10px !important;
    }

    .myproj-container ul.nav.nav-tabs li a.nav-link {
        width: 90px;
        display: flex;
        justify-content: center;
        margin-right: unset;
      }

      .myproj-container ul.nav.nav-tabs li.nav-item:first-of-type {
        margin-left: unset;
      }


    }
  }

</style>