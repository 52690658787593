<template>
  <b-row v-if="!removed">
    <div :class="is_mobile ? 'w-100' : ''">
      <b-card class="mb-2 myproj-card knowledge-card">
        <b-row class="d-flex knowledge-upperrow">
          <div :class="getTagClass" class="d-flex align-items-center library-card-tag library-card-tag-qa">
            <div v-if="data.verified">
              <img src="/assets/images/icon_library_documenttype.svg"/><span>VERIFIED</span>
            </div>
            <div v-else>
              <img src="/assets/images/icon_library_documenttype.svg"/><span> NEEDS REVIEW</span>
            </div>
          </div>
          <span v-if="data.user" class="library-card-date openSans-400 fz-12 mx-2">
            {{ $t('knowledge.questions.response.labels.by') }} {{ data.user }}
          </span>
          <span v-if="data.updated" class="library-card-date openSans-400 fz-12 mx-2">
            {{ $t('knowledge.questions.response.labels.updated') }} {{ data.updated | formatTime }}
          </span>
          <div class="knowledge-content-tags d-none">
            <img class="cursor-pointer" src="/assets/images/icon_bookmark.svg"/>
            <img  class="cursor-pointer library-document-action-icon" src="/assets/images/icon_share.svg"/>
          </div>
        </b-row>

        <div class="knowledge-content-container ta-left">
          <div v-if="!editing">
            <h3 class="openSans-600 fz-16">{{ data.title }}</h3>
            <markdown-to-html :source="data.text"></markdown-to-html>
          </div>
          <div v-else>
            <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'edit' ? 'active-tab' : ''" @click="editorMode = 'edit'">
              {{ $t('knowledge.questions.response.labels.write')}}
            </span>
            <span class="cursor-pointer margin-tabs-mode" :class="editorMode == 'preview' ? 'active-tab' : ''" @click="editorMode = 'preview'">
              {{ $t('knowledge.questions.response.labels.preview')}}
            </span>
            <v-md-editor v-model="data.text" class="margin-editor" height="400px" ref="mdEditor" :mode="editorMode"
                         left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code" right-toolbar="">
            </v-md-editor>
          </div>
        </div>

        <b-row v-if="canModify" class="knowledge-lowerrow padding-action-buttons-row">
          <div v-if="!editing">
            <span class="fz-14 openSans-400 cursor-pointer qa-card-action-icons-label" @click="editResponse">
              <img class="margin-card-action-icons" src="/assets/images/icon_checkmark.svg"> {{ $t('myApps.qaPairs.actionIconsAnswer.edit') }}
            </span>
            <span class="fz-14 openSans-400 cursor-pointer qa-card-action-icons-label" @click="removeResponse">
              <img  class="margin-card-action-icons" src="/assets/images/icon_edit.svg"/> {{ $t('myApps.qaPairs.actionIconsAnswer.remove') }}
            </span>
          </div>
          <div v-else>
            <span class="fz-14 openSans-400 cursor-pointer qa-card-action-icons-label" @click="saveResponse">
              <img class="margin-card-action-icons" src="/assets/images/icon_checkmark.svg"> {{ $t('myApps.qaPairs.actionIconsAnswer.save') }}
            </span>
            <span class="fz-14 openSans-400 cursor-pointer qa-card-action-icons-label" @click="cancelEdition">
              <img  class="margin-card-action-icons" src="/assets/images/icon_close.svg"/> {{ $t('myApps.qaPairs.actionIconsAnswer.cancel') }}
            </span>
            <span class="fz-14 openSans-400 cursor-pointer qa-card-action-icons-label" @click="removeResponse">
              <img  class="margin-card-action-icons" src="/assets/images/icon_close.svg"/> {{ $t('myApps.qaPairs.actionIconsAnswer.remove') }}
            </span>
          </div>
          <span v-if="data.url" class="fz-14 openSans-400 cursor-pointer library-document-bottom-file">
            {{ data.url }}
            <img class="knowledge-book" src="/assets/images/icon_book.svg"/> 
          </span>
          <div class="d-flex library-document-bottom-file d-none" v-if="!data.verified">
            <button type="button" class="btn openSans-300 fz-15 ta-center button-secondary ml-auto" @click="setVerified" :disabled="verifying">
              Mark as verified
            </button>
          </div>
        </b-row>

      </b-card>
    </div>
    <div class="knowledge-tags-container">
      <div class="knowledge-tags-title-container"><span class="openSans-400 fz-12">Tags:</span></div>
      <span class="openSans-400 fz-12 knowledge-tags" v-for="tag in data.tags" :key="tag">{{tag}}</span>
    </div>
    <b-overlay :show="waiting" no-wrap>
    </b-overlay>
  </b-row>
</template>

<script>
import QAService from "@/modules/knowledge/services/QAService.js"
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor'
import store from "@/store";
import MarkdownToHtml from "@/modules/common/components/MarkdownToHtml";

export default {

  name: 'ResponseCard',
  components: {MarkdownToHtml},
  props: {
    data: Object,
    inputId: String,
    inputText: String,
    callback: Function,
    canModify: Boolean
  },
  data () {
    return {
      verifying: false,
      removed: false,
      editing: false,
      editorMode: 'edit',
      rollbackText: '',
      waiting: false
    }
  },
  methods: {
    editResponse() {
      this.editing = true
      this.rollbackText = this.data.text
    },
    removeResponse() {
      this.removed = true
      QAService.removeResponse(this.inputId, this.data.id)
    },
    cancelEdition() {
      this.editing = false
      this.data.text = this.rollbackText
    },
    saveResponse() {
      this.editing = false
      const html = xss.process(VueMarkdownEditor.themeConfig.markdownParser.render(this.data.text))
      this.data.formatted = html
      this.waiting = true
      QAService.updateResponse({
        input_id: this.inputId,
        input_text: this.inputText,
        response_id: this.data.id,
        response_text: this.data.text,
        tags: this.data.tags
      }).then(() => {
        this.waiting = false
      })
    },
    setVerified() {
      let questionData = {
        bot_id: store.getters.getInstance,
        input_id: this.inputId,
        input_text: this.inputText,
        response_id: this.data.id,
        response_text: this.data.text,
        tags: this.data.tags
      }
      this.verifying = true
      this.waiting = true
      QAService.setVerified(questionData).then(() => {
        this.waiting = false
        this.callback()
      })
    }
  },
  computed: {
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    },
    getTagClass(){
      switch(this.data.status) {
        case 'myApps.qaPairs.filters.status.verified':
            return 'verified-tag';
        case 'myApps.qaPairs.filters.status.needsReview':
          return 'need-review-tag';
        default:
          return '';
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.knowledge-card {
  width: 1000px;
  margin-bottom: 50px !important;
}

.knowledge-upperrow {
  margin: 18px 0px 16px 0px !important;
}

.knowledge-content-tags {
  margin: 0px 29px 0px auto;
}

.knowledge-content-container {
  padding: 0px 50px 25px 40px
}

.qa-card-action-icons-label:not(:first-of-type) {
  margin-left: 27px;
}

.qa-card-action-icons-label {
  color: var(--viridian);
  margin-left: 10px;
}

.margin-card-action-icons {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  vertical-align: sub;
}

.knowledge-lowerrow {
  padding: 21px 25px 21px 40px;
  background-color: #fafbfc;
  border-top: 1px solid #e3eef8;
  margin: 0px;
}

.padding-action-buttons-row {
  padding-left: 19px !important;
}

.knowledge-book {
  margin-left: 19px;
}

.knowledge-tags-container {
  display: flex;
  flex-wrap: wrap;
  flex: 200px;
  max-height: 100px;
  margin-left: 25px;
}

.knowledge-tags-title-container {
  width: 100%;
  text-align: left;
  margin-left: 10px;
}

  ::v-deep {

  @media (min-width: 320px) and (max-width: 1024px) { 
    .knowledge-content-tags {
      padding: 5px 0px;
    }

    .knowledge-tags-container {
      margin-bottom: 20px;
    }

    .library-document-bottom-file {
      margin: 30px 0 0px auto;
    }
  }

  }
</style>